<script>
import { mapActions } from 'vuex'

export default {
  name: 'Medals',
  components: {
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      achievementsList: [],
      pagination: {
        page: 1
      }
    }
  },

  computed: {
    pageItems () {
      return this.achievementsList.slice((this.pagination.page - 1) * 12, ((this.pagination.page - 1) * 12) + 12)
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([ this.getAchievementsList() ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'attemptGetAchievementsList'
    ]),

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.getLastPage
    },

    nextPage () {
      if (this.pagination.page < this.getLastPage) {
        this.pagination.page += 1
      }
    },

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    getAchievementsList () {
      return this.attemptGetAchievementsList({}).then(result => {
        if (result.data) {
          result.data.forEach(element => {
            this.achievementsList.push({
              name: element.name,
              description: element.description,
              image: element.image
            })
          })

          return true
        }
      })
    },

    getLastPage () {
      return this.countPages(this.achievementsList.length, 12)
    }
  }
}
</script>

<template>
  <div
    v-if="pageItems.length > 0"
    class="center achievement-table"
  >
    <Datatable
      :class="{'first-page': pagination.page === 1}"
      :items="pageItems"
    >
      <template slot="thead">
        <tr>
          <th class="th">
            {{ $t('achievement.list:datatable.header.1') }}
          </th>
          <th class="th">
            {{ $t('achievement.list:datatable.header.2') }}
          </th>
          <th class="th">
            {{ $t('achievement.list:datatable.header.3') }}
          </th>
        </tr>
      </template>
      <template
        slot="tbody"
        slot-scope="props"
      >
        <tr>
          <td class="td">
            <span class="td-text">{{ props.item.name }}</span>
          </td>
          <td class="td">
            <span class="td-text">{{ props.item.description }}</span>
          </td>
          <td class="td">
            <span><img :src="props.item.image" class="achievement-img"></span>
          </td>
        </tr>
      </template>
    </Datatable>
    <Pagination
      :active-page="pagination.page"
      :page-count="getLastPage"
      @firstPage="firstPage"
      @lastPage="lastPage"
      @nextPage="nextPage"
      @previousPage="prevPage"
    />
  </div>
</template>

<style>
.achievement-table .datatable .th:nth-child(1) {
  padding-left: 15px;
}
.achievement-table .datatable .td:nth-child(1) {
  padding: 16px 30px;
}
.achievement-img {
  width: 80px;
  height: 80px;
}
</style>
